import React from "react"
import P from "../../core/P"
import Icon from "../../core/Icon"

export const CardRight = ({ feature, size, theme }) => {
  const SectionA = e => (
    <>
      {e.fontello && (
        <Icon
          iconName={e.fontello}
          className="text-right flex justify-end pb-5 m-0"
          theme={theme}
        />
      )}
      {e.icon && (
        <div className={`${e.title ? "pb-5" : " "} h-24 flex justify-end`}>
          <div className="w-16 rounded-md text-white ">
            <div className="w-full h-auto">
              <img
                src={e.icon?.mediaItemUrl}
                loading="lazy"
                height={e.icon?.mediaDetails?.height}
                width={e.icon?.mediaDetails?.width}
                alt={e.icon?.altText ? e.icon?.altText : e.title}
                // title={e.title}
                className={`object-contain text-right justify-items-center `}
              />
            </div>
          </div>
        </div>
      )}
      {e.title && (
        <P
          title={e.title}
          className={`font-bold text-right text-shark-400 ${e.description ? "pb-5" : " "
            }`}
          noPad
        />
      )}
      {e.description && (
        <P
          title={e.description}
          className={`text-right text-shark-400`}
          noPad
        />
      )}
    </>
  )
  const SectionB = e => (
    <>
      {e.fontello && (
        <i
          className={`${e.fontello} text-right flex justify-end pb-5 m-0 text-blue-500 icon `}
          style={{ fontSize: "70px" }}
        />
      )}
      {e.icon && (
        <div className={`${e.title ? "pb-5" : " "} h-24 flex justify-end`}>
          <div className="w-16 rounded-md text-white ">
            <div className="w-full h-auto">
              <img
                src={e?.icon?.mediaItemUrl}
                height={e?.icon?.mediaDetails?.height}
                width={e?.icon?.mediaDetails?.width}
                loading="lazy"
                alt={e?.icon?.altText ? e?.icon?.altText : e?.title}
                // title={e?.title}
                className={`object-contain text-right justify-items-center`}
              />
            </div>
          </div>
        </div>
      )}
      {e.title && (
        <P
          title={e.title}
          className={`font-bold text-right ${e?.description ? "pb-5" : " "}`}
          noPad
        />
      )}
      {e.description && (
        <P title={e?.description} className={`text-right`} noPad />
      )}
    </>
  )
  return (
    <div className="flex justify-center bg-blue-200 max-w-7xl mx-auto">
      <div className="px-4 sm:px-6 w-screen lg:px-12  ">
        <div
          className={`lg:grid lg:grid-cols-${size} lg:gap-6 `}
          data-aos="fade-up"
        >
          {feature.map(e => {
            const pointer = e.cta?.url ? "cursor-pointer" : ""
            return theme ? (
              <div
                className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-md ${e.icon
                    ? "transition border-t-4 border-white hover:shadow-xl hover:-translate-y-1"
                    : "transition duration-200 ease-in-out"
                  } `}
                key={Math.random()}
              >
                {e.cta?.url ? (
                  <a
                    href={e.cta?.url ? e.cta.url : "#"}
                    className={`${pointer}`}
                  >
                    {SectionA(e)}
                  </a>
                ) : (
                  <>{SectionA(e)}</>
                )}
              </div>
            ) : (
              <div
                className={`shadow-lg text-center p-6 my-6 lg:my-0 bg-white rounded-md ${e.icon
                    ? "transition border-t-4 hover:border-blue-500 border-white hover:shadow-xl hover:-translate-y-1 text-shark-400"
                    : "hover:bg-blue-500 hover:shadow-xl text-shark-400 hover:text-white transition duration-200 ease-in-out icon-card"
                  } `}
                key={Math.random()}
              >
                {e.cta?.url ? (
                  <a
                    href={e.cta?.url ? e.cta.url : "#"}
                    className={`${pointer}`}
                  >
                    {SectionB(e)}
                  </a>
                ) : (
                  <>{SectionB(e)}</>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default CardRight
